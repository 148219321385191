html, root{
  height: 100%;
}
.new-window-popup{
  background-color: #f0f0f0;
  height: 100%;
  .component-dialog__inner{
    height: 100%;
    width: 100%;
    margin: 0;
    max-height: unset;
    .component-dialog__content .component-base-context-menu .tab-content{
      max-height: unset;
      height: clamp(200px, 100vh, 100vh);
      overflow-y: auto !important;
    }
  }
}