@import "~@clout-team/web-components/build/assets/styles/variables.scss";
@import '~@clout-team/web-components/build/assets/styles/color-palette';

.search-field {
  padding-right: 16px;
  padding-left: 16px;
}
.tabs {
  &-list {
    padding-right: 4px;
    padding-left: 4px;

    @include theme-aware("border-color", "stroke-opacity");
    border-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-style: solid;
  }
}
.contacts-list-div{
  height: 100%;
  display: flex;
  flex-direction: column;

  // overflow: hidden;
  max-height: 100vh;
}
