@import "~@clout-team/web-components/build/assets/styles/variables.scss";
@import '~@clout-team/web-components/build/assets/styles/color-palette';

.search-field {
  padding-right: 16px;
  padding-left: 16px;
}

.tabs {
  &-list {
    box-sizing: border-box;
    max-height: 40px;
    padding-right: 4px;
    padding-left: 4px;

    @include theme-aware("border-color", "stroke-opacity");
    border-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-style: solid;
  }
}

.loading {
  margin: 0 auto;
}
.contacts-console-div{
  display: flex;
  flex-direction: column;
  height: 100%;
  &-inside{
    max-height: calc(100vh - 160px);
  }
}
