@import "~@clout-team/web-components/build/assets/styles/style";
@import "./assets/fonts/fonts";

* {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: $font-family--primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
