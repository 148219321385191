@import "~@clout-team/web-components/build/assets/styles/variables";

.working_area_contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-bg;
  padding: 40px 0;
}

.page-layout-left {
  width: 360px!important;
}

.page-layout-left__hide-arrow {
  display: none!important;
}
.component-base-context-menu.without-tabs {
  padding-top: 16px !important;
}
