.contextual-menu {
  max-width: unset;
  max-height: 488px;
  overflow-y: scroll;

  /* -------------    hiding scrollbar for browser    ---------------- */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
